import { defineStore } from "pinia";
const useNavStore = defineStore("navStore", {
  //state
  state: () => ({
    scrollTimeout: true,
  }),

  //actions
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    setScrollTimeout() {
      this.scrollTimeout = false;
      let thePiniaState = this;      
      setTimeout(function () {
        thePiniaState.scrollTimeout = true;
      }, 1000);
    },
  },

  //getters
  getters: {},
});

export { useNavStore };
