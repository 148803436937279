import { defineStore } from "pinia";
const useActiveItemStore = defineStore("activeItemStore", {
  //state
  state: () => ({
    prevActiveItemId: "",
    activeItemId: "",
  }),

  //actions
  actions: {
    // since we rely on `this`, we cannot use an arrow function
    deactivateAll() {
      this.activeItemId = "";
    },
    setActivatedItemIds(newActiveItemId) {
      this.activeItemId = newActiveItemId;
      this.prevActiveItemId = newActiveItemId;
    },
  },

  //getters
  getters: {},
});

export { useActiveItemStore };
