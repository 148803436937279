<script setup>
const props = defineProps({
  src: String,
  type: String,
});
</script>

<template>
  <video
    id="slide-video"
    controls
    controlslist="nodownload noplaybackrate"
    disablepictureinpicture
    class="full-video"
    :src="props.src"
    :type="props.type"
  >
    Apologies, your browser doesn't support playing this video.
  </video>
</template>
