<template>
  <div id="contactContainer" class="nav-item clearfix">
    <form
      v-on:submit.prevent="formSubmit"
      name="contact"
      method="post"
      class="contactForm full-paddings med-width"
      data-netlify="true"
      autocomplete="off"
    >
      <h1 class="text-center">Contact</h1>
      <label for="firstName"><span>First name</span></label>
      <input
        name="firstName"
        id="firstName"
        v-model="firstName"
        type="text"
        tabindex="1"
        required
      />

      <label for="lastName"><span>Last name</span></label>
      <input
        name="lastName"
        id="lastName"
        v-model="lastName"
        type="text"
        tabindex="2"
        required
      />

      <label for="email"><span>Email</span></label>
      <input
        name="email"
        id="email"
        v-model="email"
        type="email"
        tabindex="3"
        required
      />
      <div class="form-end">
        <label for="message"><span>Message</span></label>

        <textarea
          name="message"
          id="message"
          v-model="message"
          cols="30"
          rows="10"
          tabindex="4"
          required
        ></textarea>
        <p
          v-if="showStatusMessage"
          class="status-message text-center"
          :class="statusTextStyle"
        >
          {{ statusMessage }}
        </p>
      </div>

      <button
        id="submit"
        class="button standard-btn"
        name="submit"
        type="submit"
        value="Submit"
      >
        Send Message
      </button>
      <input type="hidden" name="contact" value="contact" />
    </form>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
//form data fields
let firstName = ref("");
let lastName = ref("");
let email = ref("");
let message = ref("");

let statusMessage = ref("");
let submitStatus = ref("");
let showStatusMessage = ref(false);

// computed classes
const statusTextStyle = computed(() => ({
  statusSuccess: submitStatus.value == "success",
  statusError: submitStatus.value == "error",
}));

// METHODS

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const formSubmit = async () => {
  if (firstName == "" || lastName == "" || email == "" || message == "") {
    statusMessage = "Please fill in required form fields";
    return;
  }

  let formDataClone = () => {
    let oldData = {
      "form-name": "contact",
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      message: message.value,
    };
    let newData = structuredClone(oldData);
    return newData;
  };

  try {
    const encodedData = encode(formDataClone());

    const response = await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodedData,
    });
    showStatusMessage.value = true;
    if (!response?.ok) {
      submitStatus.value = "error";
      statusMessage.value = "An error occurred, your form was not submitted.";
    } else {
      firstName.value = "";
      lastName.value = "";
      email.value = "";
      message.value = "";
      submitStatus.value = "success";
      statusMessage.value =
        "Success! Your form was submitted. I will be in touch soon 👋.";
    }
  } catch (error) {}

  return;
};
</script>
